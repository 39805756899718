import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SectionProps } from "../../../utils/SectionProps"
import Input from "../../elements/Input"
import Button from "../../elements/Button"
import SectionHeader from "../partials/SectionHeader"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [userEmail, setUserEmail] = useState(undefined)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSuccess(false)
    setOpenError(false)
  }

  const outerClasses = classNames(
    "section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  )

  const emailChange = event => {
    setUserEmail(event.target.value)
  }

  const iconClick = async () => {
    if (userEmail !== undefined && userEmail !== "") {
      try {
        let data = {}
        data["group[32969][1]"] = 1
        const result = await addToMailchimp(userEmail, data)
        if (result.result === "success") {
          setOpenSuccess(true)
        }
        if (result.result === "error") {
          setOpenError(true)
          console.error(result.msg)
        }
        setUserEmail("")
      } catch (error) {
        setOpenError(true)
        console.error(error.toString())
        setUserEmail("")
      }
    }
  }

  const sectionHeader = {
    title: (
      <>
        Sign up for <span className="text-color-primary">Wizart DCC</span> news!
      </>
    ),
  }

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content"
            style={{
              paddingBottom: 20,
            }}
          />
          <Input
            id="subscribe"
            type="email"
            label="Subscribe"
            labelHidden
            hasIcon="right"
            placeholder="Email Address"
            onChange={e => emailChange(e)}
            className={"cta-input"}
            value={userEmail}
          />
          <p className="mt-16 mb-16 text-xxs">
            By submitting your information, you are agreeing to receive news
            from Wizartsoft
          </p>
          <div className={"subscribe-btn"}>
            <Button color="primary" wideMobile onClick={() => iconClick()}>
              Subscribe
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          Thank you for subscribing!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error!
        </Alert>
      </Snackbar>
    </section>
  )
}

Cta.propTypes = propTypes
Cta.defaultProps = defaultProps

export default Cta
